<template>
    <div class="prices-watch">
        <div class="loading" v-if="!assortments">
            <p>טוען, אנא המתן..</p>
        </div>
        <div class="data-table">
            <table id="table">
                <tr>
                    <th>גיוון</th>
                    <th>שם</th>
                    <th>פריטים</th>
                    <th>צפייה במחירים</th>
                    <th>שינוי</th>
                </tr>
                <template v-for="assortment in assortments" :key="assortment.makat">
                    <tr>
                        <td>{{assortment.makat}}</td>
                        <td>{{assortment.name}}</td>
                        <td>{{assortment.items.length}}</td>
                        <td>
                            <p v-if="assortment.prices"> יכולים לראות </p>
                            <p v-if="!assortment.prices"> לא יכולים לראות </p>
                        </td>
                        <td v-if="assortment.prices">
                            <el-button type="danger" @click="update_prices(assortment, false)">שנה ללא</el-button>
                        </td>
                        <td v-if="!assortment.prices">
                            <el-button type="success" @click="update_prices(assortment, true)">שנה לכן</el-button>
                        </td>
                    </tr>
                </template>
            </table>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from '@vue/runtime-core'
import { projectFirestore } from '../../../firebase/config';
import { slide_pop_successs } from '../../../Methods/Msgs';
export default {
setup(){
    const assortments = ref(null);

    const get_assortments = async () => {
        const docs = await projectFirestore.collection('Assortments').get()
        if(!docs.empty){
            assortments.value = docs.docs.map(doc => doc.data());
        }
    }

    const update_prices = async (assortment, bool) => {
        if(bool){
            await projectFirestore.collection('Assortments').doc(assortment.makat).update({
                prices: true
            })
            assortment.prices = true;
            slide_pop_successs("עודכן בהצלחה!")
        }else{
            await projectFirestore.collection('Assortments').doc(assortment.makat).update({
                prices: false
            })
            assortment.prices = false;
            slide_pop_successs("עודכן בהצלחה!")
        }
    }

    onMounted(() =>{
        get_assortments();
    })
    return{
        assortments, update_prices
    }
}
}
</script>

<style scoped>
.prices-watch{
    width: 100%;
    height: 100%;
}
.loading{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    font-size: 26px;
}
.data-table{
    width: 100%;
    height: calc(100% - 10px);
    overflow-y: auto;
}
#table {
    margin-top: 10px;
    border-collapse: collapse;
    width: 100%;
}
#table td, #table th {
  border: 1px solid #ddd;
  padding: 8px;
}
#table tr:nth-child(even){background-color: #f2f2f2;}
#table tr:nth-child(odd){background-color: #e7e6e6;}

#table tr:hover {background-color: #ddd;}

#table td:last-child{
    text-align: center;
    color: var(--secondary);
}
#table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: var(--purple);
  color: white;
  position: sticky;
  top: 0;
}
</style>